<template>
  <div>
    <v-form v-model="valid" ref="form">
      <v-app
        id="inspire"
        class="gradient-background"
        style="width: 100%; height: 100%"
      >
        <v-main>
          <v-row class="mx-auto text-center mt-n15">
            <v-col cols="12">
              <v-stepper
                alt-labels
                style="max-width: 400px; border: none; background-color: #0000"
                class="elevation-0 mx-auto"
              >
                <v-stepper-header>
                  <v-stepper-step step="1"></v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step step="2"></v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step step="3"></v-stepper-step>
                </v-stepper-header>
              </v-stepper>
            </v-col>
            <v-col cols="12" class="mt-n6">
              <div class="registerFont" style="color: #2e2e2e">
                {{ $t("message.registerByTel") }}
              </div>
            </v-col>
            <v-col cols="12" class="mt-n6">
              <v-card
                style="
                  width: 600px;
                  border: 0px;
                  margin: 0 auto;
                  margin-bottom: 180px;
                "
                class="pa-8"
              >
                <v-row>
                  <v-col cols="12">
                    <span class="registerFont-1">{{
                      $t("message.sendToPhone")
                    }}</span>
                  </v-col>
                  <v-col cols="12" class="text-start">
                    <span>{{ $t("message.telnumber") }}</span>
                    <v-text-field
                      v-model="form.telNumber"
                      type="number"
                      placeholder="xxx-xxx-xxxx"
                      dense
                      outlined
                      hide-details="auto"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="text-start">
                    <span>{{ $t("message.firstname") }}</span>
                    <span></span>
                    <v-text-field
                      v-model="form.firstName"
                      :placeholder="$t('message.firstname')"
                      dense
                      outlined
                      hide-details="auto"
                      :rules="[requiredRule]"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="text-start">
                    <span>{{ $t("message.lastname") }}</span>
                    <v-text-field
                      v-model="form.lastName"
                      :placeholder="$t('message.lastname')"
                      dense
                      outlined
                      hide-details="auto"
                      :rules="[requiredRule]"
                      required
                    ></v-text-field>
                  </v-col>
                  <!-- <v-col cols="12" class="text-start">
                    <span>{{ $t("message.password") }}</span>
                    <v-text-field
                      v-model="form.password"
                      type="password"
                      :placeholder="$t('message.password')"
                      dense
                      outlined
                      hide-details="auto"
                      :rules="[requiredRule]"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="text-start">
                    <span>{{ $t("message.confirmpassword") }}</span>
                    <v-text-field
                      v-model="form.confirmpassword"
                      :placeholder="$t('message.confirmpassword')"
                      dense
                      outlined
                      hide-details="auto"
                      :rules="[requiredRule]"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" class="text-start">
                    <span>{{ $t("message.email") }}</span>
                    <v-text-field
                      v-model="form.email"
                      :placeholder="$t('message.email')"
                      dense
                      outlined
                      hide-details="auto"
                      :rules="rules.email"
                      required
                      type="email"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="text-start">
                    <span>{{ $t("message.bank") }}</span>
                    <v-autocomplete
                      v-model="form.bank"
                      :placeholder="$t('message.bank')"
                      item-text="name"
                      item-value="name"
                      :items="bankItems"
                      dense
                      outlined
                      hide-details="auto"
                      :rules="[requiredRule]"
                      required
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" class="text-start">
                    <span>{{ $t("message.accountName") }}</span>
                    <v-text-field
                      v-model="form.accountName"
                      :placeholder="$t('message.accountName')"
                      dense
                      outlined
                      hide-details="auto"
                      :rules="[requiredRule]"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="text-start">
                    <span>{{ $t("message.accountNo") }}</span>
                    <v-text-field
                      v-model="form.accountNo"
                      :placeholder="$t('message.accountNo')"
                      dense
                      outlined
                      hide-details="auto"
                      :rules="[requiredRule]"
                      required
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="text-start">
                    <span>{{ $t("message.deliveryaddress") }}</span>
                    <v-text-field
                      v-model="form.deliveryaddress"
                      :placeholder="$t('message.deliveryaddress')"
                      dense
                      outlined
                      hide-details="auto"
                      :rules="[requiredRule]"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="text-start">
                    <span>{{ $t("message.postcode") }}</span>
                    <v-text-field
                      v-model="form.postcode"
                      :placeholder="$t('message.postcode')"
                      dense
                      outlined
                      hide-details="auto"
                      :rules="[requiredRule]"
                      required
                    ></v-text-field>
                  </v-col> -->

                  <v-col cols="12" class="text-start">
                    <v-row>
                      <v-col cols="1">
                        <v-checkbox v-model="checkbox1" required></v-checkbox>
                      </v-col>
                      <v-col cols="11">
                        <v-expansion-panels>
                          <v-expansion-panel>
                            <v-expansion-panel-header class="text-center">
                              <div>
                                <span
                                  style="font-size: 18px; font-weight: bold"
                                >
                                  ขอรับรองว่า ฉันไม่ได้ซื้อลอตเตอรี่ในสถานศึกษา
                                  และ ปัจจุบันฉันมีอายุครบ 20 ปี บริบูรณ์
                                </span>
                              </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              ฉัน ในฐานะผู้ซื้อลอตเตอรี่ ตกลงและทราบดีว่า.
                              www.dragon-mahaburapha.comไม่จำหน่ายลอตเตอรี่ในสถานศึกษา
                              และให้กับบุคคลที่อายุต่ำกว่า 20 ปี
                              ทางwww.dragon-mahaburapha.com
                              ขอสงวนสิทธิ์ในการดำเนินคดีตามกฎหมายสูงสุด
                              ทั้งทางแพ่งและอาญา
                              กับผู้ที่มาซื้อลอตเตอรี่กับwww.dragon-mahaburapha.com
                              โดยมีอายุต่ำกว่า 20 ปีบริบูรณ์ หรือซื้อในสถานศึกษา
                              <br />
                              <br />
                              ฉันตกลงยินยอม และยอมรับเงื่อนไขต่างๆ
                              ที่ทางwww.dragon-mahaburapha.comกำหนดขึ้น
                              ทั้งในปัจจุบันและหรือในอนาคต
                              เพื่อตรวจสอบอายุผู้ซื้อลอตเตอรี่ ว่าไม่ต่ำกว่า 20
                              ปีบริบูรณ์
                              <br />
                              <br />
                              www.dragon-mahaburapha.comขอสงวนสิทธิ์ในการขอตรวจสอบอายุผู้ซื้อ
                              และฉันตกลงยอมจ่ายค่าปรับให้กับทางwww.dragon-mahaburapha.com
                              เป็นเงิน 10,000 (หนึ่งหมื่นบาทถ้วน)
                              ต่อการซื้อลอตเตอรี่ 1 ใบ
                              ในกรณีที่www.dragon-mahaburapha.comตรวจพบว่า
                              ฉันซื้อลอตเตอรี่ในสถานศึกษา
                              และหรืออายุฉันยังไม่ถึง 20 ปีบริบูรณ์
                              <br />
                              ฉันตกลงและทราบดีว่า ทาง www.dragon-mahaburapha.com
                              ขอสงวนสิทธิ์ในการไม่โอนเงินรางวัลในกรณีผู้ซื้อถูกรางวัล
                              และจะไม่คืนเงินค่าซื้อลอตเตอรี่ให้กับผู้ซื้อลอตเตอรี่.
                              ในกรณีที่www.dragon-mahaburapha.comตรวจพบว่า
                              ผู้ซื้อมีอายุต่ำกว่า 20 ปีบริบูรณ์ **
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" class="text-start">
                    <v-row>
                      <v-col cols="1">
                        <v-checkbox v-model="checkbox2" required></v-checkbox>
                      </v-col>
                      <v-col cols="11">
                        <v-expansion-panels>
                          <v-expansion-panel>
                            <v-expansion-panel-header class="text-center">
                              <div>
                                <span
                                  style="font-size: 18px; font-weight: bold"
                                >
                                  ข้าพเจ้าเข้าใจดีว่า ผู้ให้บริการ จะเก็บรวบรวม
                                  ใช้
                                  และเปิดเผยข้อมูลส่วนบุคคลของข้าพเจ้าเพื่อวัตถุประสงค์ในการให้บริการตามสัญญานี้
                                  การวิเคราะห์ข้อมูลเพื่อวางแผนทางการตลาดการนำเสนอสินค้าและบริการอื่นๆ
                                  ของผู้ให้บริการแก่ข้าพเจ้า
                                  รวมถึงวัตถุประสงค์อื่นๆ
                                  ตามที่ผู้ให้บริการเห็นสมควร
                                </span>
                              </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              ข้อกำหนดและเงื่อนไขฉบับนี้ ถือเป็นข้อตกลงระหว่าง
                              www.dragon-mahaburapha.com
                              กับผู้ซื้อหรือผู้รับบริการ
                              <br />
                              <br />
                              1. “ผู้ซื้อ” หมายถึง ผู้ขอใช้บริการ ผู้ใช้บริการ
                              สมาชิก ตลอดจนผู้มีส่วนเกี่ยวข้อง
                              <br />
                              <br />
                              2. “บริการ” หมายถึง
                              บริการของผู้ให้บริการภายใต้ข้อกำหนดและเงื่อนไขฉบับนี้
                              <br />
                              <br />
                              3.“ผู้ให้บริการ” หมายถึง
                              www.dragon-mahaburapha.com / มังกรมหาบูรพา
                              <br />
                              <br />
                              4. “บริษัทฯ” หมายถึง www.dragon-mahaburapha.com /
                              มังกรมหาบูรพา
                              <br />
                              <br />
                              5. “สลากกินแบ่งรัฐบาล” หมายถึง
                              สลากกินแบ่งรัฐบาลที่ออกโดยสำนักงานสลากกินแบ่งรัฐบาลทุกประเภท
                              <br />
                              <br />
                              6. “แพลตฟอร์ม” หมายถึง
                              “https://www.dragon-mahaburapha.com” หรือ “
                              https://dragon-mahaburapha.com ” หรือ
                              “แอปพลิเคชันwww.dragon-mahaburapha.com”
                              ซึ่งเป็นบริษัทฯ ผู้ให้บริการจำหน่าย รับจัดเก็บ
                              รับฝาก
                              รับขึ้นเงินสลากกินแบ่งรัฐบาลตลอดจนประกอบกิจการอื่น
                              ๆ ตามวัตถุประสงค์ของบริษัทฯ
                              <br />
                              <br />
                              7. ผู้ซื้อยินยอมให้บริษัทฯ จัดเก็บข้อมูลส่วนบุคคล
                              (Personal Data)
                              ตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคลพ.ศ.2562
                              PDPA (Personal Data Protection Act B.E. 2562
                              (2019)) เพื่อมีไว้ใช้และเปิดเผย
                              ซึ่งข้อมูลส่วนบุคคล ได้แก่ชื่อ-นามสกุล
                              หรือชื่อเล่น / เลขประจำตัวประชาชน,
                              เลขหนังสือเดินทาง, เลขบัตรประกันสังคม,
                              เลขใบอนุญาตขับขี่,เลขประจำตัวผู้เสียภาษี,
                              เลขบัญชีธนาคาร, เลขบัตรเครดิต
                              (การเก็บเป็นภาพสำเนาบัตรประชาชนหรือสำเนาบัตรอื่น
                              ๆที่มีข้อมูลส่วนบุคคลที่กล่าวมาย่อมสามารถใช้ระบุตัวบุคคลได้โดยตัวมันเอง
                              จึงถือเป็นข้อมูลส่วนบุคคล) / ที่อยู่,
                              อีเมล์,เลขโทรศัพท์ / ข้อมูลอุปกรณ์หรือเครื่องมือ
                              เช่น IP address, MAC address, Cookie ID /
                              ข้อมูลระบุทรัพย์สินของบุคคลเช่น ทะเบียนรถยนต์,
                              โฉนดที่ดิน /
                              ข้อมูลที่สามารถเชื่อมโยงไปยังข้อมูลข้างต้นได้ เช่น
                              วันเกิดและสถานที่เกิด,เชื้อชาติ,สัญชาติ, น้ำหนัก,
                              ส่วนสูง, ข้อมูลตำแหน่งที่อยู่ (location),
                              ข้อมูลการแพทย์, ข้อมูลการศึกษา,
                              ข้อมูลทางการเงิน,ข้อมูลการจ้างงาน /
                              ข้อมูลหมายเลขอ้างอิงที่เก็บไว้ในไมโครฟิล์ม
                              แม้ไม่สามารถระบุไปถึงตัวบุคคลได้แต่หากใช้ร่วมกับระบบดัชนีข้อมูลอีกระบบหนึ่งก็จะสามารถระบุไปถึงตัวบุคคลได้
                              /ข้อมูลการประเมินผลการทำงานหรือความเห็นของนายจ้างต่อการทำงานของลูกจ้าง
                              / ข้อมูลบันทึกต่าง ๆที่ใช้ติดตามตรวจสอบกิจกรรมต่าง
                              ๆ ของบุคคล เช่น log file
                              /ข้อมูลที่สามารถใช้ในการค้นหาข้อมูลส่วนบุคคลอื่นในอินเทอร์เน็ต
                              ตลอดจนข้อมูลส่วนบุคคลที่มีความละเอียดอ่อน(Sensitive
                              Personal Data) ได้แก่ ข้อมูล เชื้อชาติ,
                              เผ่าพันธุ์, ความคิดเห็นทางการเมือง,
                              ความเชื่อในลัทธิศาสนาหรือปรัชญา, พฤติกรรมทางเพศ,
                              ประวัติอาชญากรรม, ข้อมูลสุขภาพ ความพิการ
                              หรือข้อมูลสุขภาพจิต,ข้อมูลสหภาพแรงงาน,
                              ข้อมูลพันธุกรรม, ข้อมูลชีวภาพ, ข้อมูลทางชีวมิติ
                              (Biometric) เช่น รูปภาพใบหน้า,
                              ลายนิ้วมือ,ฟิล์มเอกซเรย์, ข้อมูลสแกนม่านตา,
                              ข้อมูลอัตลักษณ์เสียง, ข้อมูลพันธุกรรม
                              เป็นต้นและข้อมูลอื่นใดซึ่งกระทบต่อเจ้าของข้อมูลในทำนองเดียวกันตามที่คณะกรรมการประกาศกำหนด
                              <br />
                              <br />
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12">
                    <v-btn
                      :disabled="!checkbox1 || !checkbox2"
                      class="custom-btn"
                      @click="submit()"
                    >
                      <span style="font-size: 20px; font-weight: bold">{{
                        $t("message.confirmregister")
                      }}</span></v-btn
                    >
                  </v-col>
                  <v-col cols="12">
                    <span
                      style="font-size: 20px; font-weight: bold; color: red"
                      >{{ $t("message.stopselling") }}</span
                    >
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-main>
      </v-app>
    </v-form>
    <DialogLogin :dialog="dialogLogin" @closeDialogLogin="closeDialogLogin" />
  </div>
</template>


<script>
import { Encode, Decode } from "@/services";
import DialogLogin from "./Dialog/DialogLogin.vue";

export default {
  components: {
    DialogLogin,
  },

  data: () => ({
    socialId: "",
    dialogLogin: false,
    valid: false,

    e1: 1,
    checkbox1: false,
    checkbox2: false,
    checkEmail: false,
    form: {
      telNumber: null,
      firstName: null,
      lastName: null,
      password: "9999",
      confirmpassword: null,
      email: "email",
      bank: "",
      accountName: null,
      accountNo: null,
      deliveryaddress: null,
      postcode: null,
    },
    id: "",

    bankItems: [
      { name: "ธนาคารกรุงเทพ" },
      { name: "ธนาคารกสิกรไทย" },
      { name: "ธนาคารกรุงไทย" },
      { name: "ธนาคารทหารไทยธนชาต" },
      { name: "ธนาคารไทยพาณิชย์" },
      { name: "ธนาคารกรุงศรีอยุธยา" },
      { name: "ธนาคารเกียรตินาคินภัทร" },
      { name: "ธนาคารซีไอเอ็มบีไทย" },
      { name: "ธนาคารทิสโก้" },
      { name: "ธนาคารยูโอบี" },
      { name: "ธนาคารไทยเครดิต" },
      { name: "ธนาคารแลนด์ แอนด์ เฮ้าส์" },
      { name: "ธนาคารไอซีบีซี (ไทย)" },
      { name: "ธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย" },
      { name: "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร" },
      { name: "ธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย" },
      { name: "ธนาคารออมสิน" },
      { name: "ธนาคารอาคารสงเคราะห์" },
    ],

    rules: {
      required: [(v) => !!v || this.$t("message.pleasfill")],
      status: [(v) => !!v || "กรุณาระบุสถานะของนิสิต/บุคลากร"],
      email: [(v) => !!v || "กรุณากรอกฮีเมล"],
      passward: [(v) => !!v || "กรุณาใส่รหัสผ่าน"],
      passwardConfirm: [(v) => !!v || "กรุณายืนยันรหัสผ่าน"],
      title: [(v) => !!v || "กรุณากรอกข้อมูลส่วนบุคคล"],
      affiliation: [(v) => !!v || "กรุณาเลือกข้อมูลคณะ"],
      passport: [
        (v) => /[a-zA-Z]{2}[0-9]{7}/.test(v) || "เลขพาสปอร์ตไม่ถูกต้อง",
      ],
    },
  }),
  watch: {},
  mounted() {},
  created() {
    if (this.$router.currentRoute.query) {
      this.socialId = this.$router.currentRoute.query.socialId;
      console.log("this.socialId", this.socialId);
    }
  },
  methods: {
    requiredRule(v) {
      return !!v || this.$t("message.pleasfill");
    },
    maxLengthRule() {
      const sanitizedValue =
        this.form.telNumber && this.form.telNumber.replace(/[^0-9]/g, "");
      return (
        (sanitizedValue && sanitizedValue.length === 10) ||
        this.$t("message.digit10length")
      );
    },

    async submit() {
      this.$refs.form.validate();
      if (this.valid) {
        // this.validPasswordConfirm();
        this.submitResgister();
      }
    },
    async submitResgister() {
      const data = {
        socialId: this.socialId,
        telNumber: this.form.telNumber.trim(),
        firstName: this.form.firstName,
        lastName: this.form.lastName,
        agentId: 6,
      };
      if (this.socialId == undefined) {
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/users/register`,
          data
        );

        if (response.data.response_status === "SUCCESS") {
          this.$swal
            .fire({
              icon: "success",
              text: this.$t("message.registerSuccess"),
              showConfirmButton: false,
              timer: 1500,
            })
            .then(() => {
              this.cancel();
            });
        } else {
          this.$swal.fire({
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            icon: "error",
            text: response.data.message,
          });
        }
      } else {
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/users/registerLine`,
          data
        );

        if (response.data.response_status === "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: this.$t("message.registerSuccess"),
            showConfirmButton: false,
            timer: 1500,
          });
          localStorage.setItem("MAHAUser", Encode.encode(response.data.data));
          this.$router.currentRoute.path == "/"
            ? this.$router.go(0)
            : this.$router.push("/").then(() => {
                this.$router.go(0);
              });
        } else {
          this.$swal.fire({
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            icon: "error",
            text: response.data.message,
          });
        }
      }
    },
    cancel() {
      this.dialogLogin = true;
      this.$router.push("/");
    },
    closeDialogLogin() {
      this.dialogLogin = false;
    },
    validPasswordConfirm() {
      if (this.form.confirmpassword !== this.form.password) {
        this.checkPasswordConfirm = false;
        this.$swal.fire({
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true,
          icon: "error",
          text: this.$t("message.passwordNotMatch"),
        });
      } else {
        this.checkPasswordConfirm = true;
      }
    },
    validEmail() {
      const mailformat =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (mailformat.test(this.form.email)) {
        this.checkEmail = true;
      } else if (this.form.email == "") {
        this.checkEmail = false;
        this.$swal.fire({
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true,
          icon: "error",
          text: this.$t("message.validEmail"),
        });
      } else {
        this.checkEmail = false;
        this.$swal.fire({
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true,
          icon: "error",
          text: this.$t("message.validEmail"),
        });
      }
    },
  },
};
</script>

<style>
</style>

<style scoped>
::v-deep .v-stepper__step__step {
  font-size: 1.5rem;
  height: 34px !important;
  width: 34px !important;
}

.custom-btn {
  min-width: 250px !important;
}

.registerFont {
  font-size: 2.5em !important;
}
.registerFont-1 {
  font-size: 1.5em !important;
}

@media (max-width: 900px) {
  .registerFont {
    font-size: 2em !important; /* Add !important to ensure it takes precedence */
  }

  .registerFont-1 {
    font-size: 1.3em !important;
  }
}

@media (max-width: 600px) {
  .registerFont {
    font-size: 1.5em !important; /* Add !important to ensure it takes precedence */
  }

  .registerFont-1 {
    font-size: 1em !important;
  }
}
</style>
